import React from "react"
import { Layout } from "../components"

const about = () => {
  return (
    <Layout>
      <div>about</div>
    </Layout>
  )
}

export default about
